export const currencyFormat = function (
  amount: number,
  params?: { locales?: string; options?: Intl.NumberFormatOptions }
) {
  const { locales = "fr" } = params ?? {};
  const { options = {} } = params ?? {};
  const { currency = "EUR" } = options;

  return new Intl.NumberFormat(locales, {
    style: "currency",
    currency,
    ...options,
  }).format(amount);
};
